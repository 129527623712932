
<template>
  <div>
    <header class="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4---">
      <div class="ltn__header-top-area">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="ltn__top-bar-menu">
                <ul>
                  <li><a :href="'mailto:'+contact.mail"><i class="icon-mail"></i> {{ contact.mail }}</a></li>
                  <li><router-link to="/iletisim"><i class="icon-placeholder"></i> {{ contact.addr }}</router-link></li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="top-bar-right text-end">
                <div class="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <div class="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                        <ul>
                          <li class="dropdown-toggle">
                            <span class="active-currency" v-for="item in langs" :class="[item.kisaltma == currentLang ? '' : 'd-none']">{{ item.ad }}</span>
                            <ul>
                              <li v-for="item in langs" @click="langChange(item.kisaltma)" :class="[item.kisaltma == currentLang ? 'd-none' : '']">{{ item.ad }}</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div class="ltn__social-media">
                        <ul>
                          <li><a :href="contact.instagram" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
                          <li><a :href="contact.facebook" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                          <li><a :href="contact.youtube" target="_blank" title="Facebook"><i class="fab fa-youtube"></i></a></li>
                          <!--                                            <li><a href="#" title="Twitter"><i class="fab fa-twitter"></i></a></li>-->
                          <!--                                            <li><a href="#" title="Dribbble"><i class="fab fa-dribbble"></i></a></li>-->
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="site-logo-wrap">
                <div class="site-logo">
                  <router-link to="/anasayfa"><img src="../../../public/assets/logo.png" alt="Logo"></router-link>
                </div>
              </div>
            </div>
            <div class="col header-menu-column">
              <div class="header-menu d-none d-xl-block">
                <nav>
                  <div class="ltn__main-menu">
                    <ul>
                      <li><router-link to="/anasayfa" title="Anasayfa" rel="nofollow">{{ langcontent.anasayfa }}</router-link></li>
                      <li><router-link to="/kurumsal" title="Kurumsal" rel="nofollow">{{ langcontent.kurumsal }}</router-link></li>
                      <li class="menu-icon">
                        <router-link to="/kategoriler" title="Ürünler" rel="nofollow">{{ langcontent.urunler }}</router-link>
                        <ul>
                          <li v-for="item in categories">
                            <router-link :to="'/kategori/'+item.seo" :title="item.seo" rel="nofollow">{{ item.heading }}</router-link>
                          </li>
                        </ul>
                      </li>
                      <li><router-link to="/uretim" title="Üretim" rel="nofollow">{{ langcontent.uretim }}</router-link></li>
                      <li><router-link to="/kalite" title="Kalite" rel="nofollow">{{ langcontent.kalite }}</router-link></li>
                      <li><router-link to="/iletisim" title="İletişim" rel="nofollow">{{ langcontent.iletisim }}</router-link></li>
                      <li class="special-link">
                        <a :href="catalog.catalog" download target="_blank" title="Katalog" rel="nofollow">{{ langcontent.onlinekatalog }}</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div class="col--- ltn__header-options ltn__header-options-2 ">
              <div class="mobile-menu-toggle d-xl-none">
                <a href="#ltn__utilize-mobile-menu" class="ltn__utilize-toggle">
                  <svg viewBox="0 0 800 600">
                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                    <path d="M300,320 L540,320" id="middle"></path>
                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div id="ltn__utilize-mobile-menu" class="ltn__utilize ltn__utilize-mobile-menu">
      <div class="ltn__utilize-menu-inner ltn__scrollbar">
        <div class="ltn__utilize-menu-head">
          <div class="site-logo">
            <a href="/anasayfa" title="Logo" rel="nofollow"><img src="../../../public/assets/logo.png" alt="Logo"></a>
          </div>
          <button class="ltn__utilize-close">×</button>
        </div>
        <div class="ltn__utilize-menu">
          <ul @click="menu">
            <li><a href="/anasayfa" title="Anasayfa" rel="nofollow">{{ langcontent.anasayfa }}</a></li>
            <li><router-link to="/kurumsal" title="Kurumsal" rel="nofollow">{{ langcontent.kurumsal }}</router-link></li>
            <li><router-link to="/kategoriler" title="Ürünler" rel="nofollow">{{ langcontent.urunler }}</router-link></li>
            <li><router-link to="/uretim" title="Üretim" rel="nofollow">{{ langcontent.uretim }}</router-link></li>
            <li><router-link to="/iletisim" title="İletişim" rel="nofollow">{{ langcontent.iletisim }}</router-link></li>
          </ul>
        </div>
        <div class="ltn__social-media-2">
          <ul>
            <li><a :href="contact.instagram" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
            <li><a :href="contact.facebook" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
            <li><a :href="contact.youtube" target="_blank" title="Facebook"><i class="fab fa-youtube"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ltn__utilize-overlay"></div>
  </div>
</template>

<script>
import iso from "../../axios";
export default {
  props:['langcontent'],
  data() {
    return {
      categories: [],
      langs: [],
      currentLang:'tr',
      contact: [],
      catalog:''
    }
  },
  created() {
    this.currentLang = localStorage.lang

    iso.get('diller')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.langs.push(data[key])
          }
        })
    iso.get('categories')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.categories.push(data[key])
          }
        })
    iso.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('catalog')
        .then(response => {
          this.catalog = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods:{
    langChange(e) {
      const firstPath = window.location.pathname.split('/')[1];
      if (firstPath == 'kategori' || firstPath == 'urun'){
        localStorage.lang = e
        document.location.href="/";
      }else{
        localStorage.lang = e
        location.reload(true);
      }
    },
    menu: function() {
      $('body').removeClass('ltn__utilize-open');
      $('.ltn__utilize').removeClass('ltn__utilize-open');
      $('.ltn__utilize-overlay').fadeOut();
      $('.mobile-menu-toggle').find('a').removeClass('close');
    }
  }
}
</script>