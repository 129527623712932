import {createRouter, createWebHistory} from 'vue-router'
import Home from '../components/views/Home.vue'
const routes = [
    {
        path: '/',
        name: 'Anasayfa',
        component: Home
    },
    {
        path: '/anasayfa',
        name: 'Anasayfa ',
        component: Home
    },
    {
        path: '/kurumsal',
        name: 'Kurumsal',
        component: () => import('../components/views/About.vue')
    },
    {
        path: '/uretim',
        name: 'Üretim',
        component: () => import('../components/views/Production.vue')
    },
    {
        path: '/kalite',
        name: 'Kalite',
        component: () => import('../components/views/Quality.vue')
    },
    {
        path: '/kategoriler',
        name: 'Kategoriler',
        component: () => import('../components/views/Categories.vue')
    },
    {
        path: '/kategori/:mId',
        name: 'Ürünler',
        component: () => import('../components/views/Category.vue')
    },
    {
        path: '/urun/:mId',
        name: 'Ürünler ',
        component: () => import('../components/views/Products.vue')
    },
    {
        path: '/iletisim',
        name: 'İletişim',
        component: () => import('../components/views/Contact.vue')
    },
    {
        path: '/yonet',
        name: 'Yönet',
        component: () => import('../components/views/Admin.vue'),
        meta: {
            header: 'none'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next)=>{
    document.title = 'Uyanık Yatağanlı | '+to.name
    next()
})
export default router
