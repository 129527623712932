<template>
  <div class="page-wrapper">

    <appHeader v-if="$route.meta.header != 'none'" :langcontent="langcontent" />

    <router-view v-slot="{ Component }">

      <transition enter-active-class="slide-fade-enter-active"
                 leave-active-class="slide-fade-leave-active">
      <component :is="Component" :langcontent="langcontent">
      </component>

      </transition>
    </router-view>

    <appFooter v-if="$route.meta.header != 'none'" :langcontent="langcontent"/>
  </div>
</template>
<script>
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import iso from "./axios";
export default {
  components : {
    "appHeader" : Header,
    "appFooter" : Footer,
  },

  data() {
    return {
      langcontent:['null'],
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          let data = response.data[0]
          this.langcontent = data

        })
        .catch(error => {
          console.log(error);
        })

    }
  }
</script>
<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .5s ease;
}

.slide-fade-enter,.slide-fade-leave-active {
  padding-left: 100px;
  opacity: 0;
}


</style>
