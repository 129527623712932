<template>
  <div>
    <div class="container-fluid">
      <carousel v-if="show" :items-to-show="1" :wrapAround="true">
        <slide  v-for="(item, index) in 3" :key="index">
          <div class="sliderContent">
            <div class="sliderText">
              <div class="slide-item-info-inner"><h1 class="slide-title" v-html="sliderItems[index].text"></h1></div>
              <div class="slide-brief" v-html="sliderItems[index].desc"></div>
            </div>
            <div class="sliderImg">
              <img :src="sliderItems[index].img" alt="#">
            </div>
          </div>

        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>

    <div class="ltn__about-us-area pt-115 pb-100 ">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <div class="about-us-img-wrap about-img-left">
              <img :src="about.img" alt="kurumsal resim">
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="about-us-info-wrap">
              <div id="app" class="section-title-area ltn__section-title-2--- mb-30">
                <h6 class="section-subtitle section-subtitle-2--- ltn__secondary-color">Uyanık Yatağanlı</h6>
                <h1 class="section-title">{{ about.heading }}</h1>
                <div class="fixFirst">{{ about.text }}</div>
                <div class="btn-wrapper animated">
                  <router-link to="/kurumsal" class="theme-btn-1 btn btn-effect-1">{{ langcontent.kurumsal }}</router-link>
                </div>
              </div>
              <div class="ltn__feature-item ltn__feature-item-3">
                <div class="ltn__feature-icon">
                  <span><i class="flaticon-maps-and-location"></i></span>
                </div>
                <div class="ltn__feature-info">
                  <h4><a>{{ langcontent.uretimalani }}</a></h4>
                  <p>{{ langcontent.uretimalaniaciklama }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ltn__about-us-area section-bg-1 bg-image-right-before pt-120 pb-90" :style="'--img:url('+video.img+');'" >
      <div class="container">
        <div class="row">
          <FsLightbox
              :toggler="toggler"
              :sources="[video.url]"
          />
          <div class="col-lg-6 align-self-center">
            <div class="about-us-info-wrap">
              <div class="section-title-area ltn__section-title-2--- mb-20">
                <h6 class="section-subtitle section-subtitle-2--- ltn__secondary-color">Uyanık Yatağanlı</h6>
                <h1 class="section-title">{{ video.heading }}</h1>
                <p>
                  {{ video.text }}
                </p>
              </div>
              <div class="  ltn__animation-pulse2 text-center mt-30">
                <a class="ltn__video-play-btn bg-white--- ltn__secondary-bg" @click="toggler = !toggler">
                  <i class="icon-play  ltn__secondary-color--- white-color"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="about-us-img-wrap about-img-left">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-area ltn__section-title-2--- text-center">
              <h1 class="section-title">{{ langcontent.kategoriler }}</h1>
            </div>
          </div>
        </div>
        <div class="row ltn__category-slider-active--- slick-arrow-1 justify-content-center rowEQ">
          <div v-for="item in categories" class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">

            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <router-link :to="'/kategori/'+item.seo" class="aa">
                <div>
                  <img :src="item.img" alt="">
                </div>
                <span class="category-title">{{ item.heading }}</span>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="ltn__about-us-area pb-115">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 align-self-center">
            <div class="about-us-img-wrap ltn__img-shape-left  about-img-left">
              <img :src="production.img" alt="uretim">
            </div>
          </div>
          <div class="col-lg-7 align-self-center">
            <div class="about-us-info-wrap">
              <div class="section-title-area ltn__section-title-2--- mb-20">
                <h1 class="section-title">{{ production.heading }}</h1>
                <div class="fixTwo">
                  {{ production.text }}
                </div>
                <div class="btn-wrapper animated">
                  <router-link to="uretim" class="theme-btn-1 btn btn-effect-1">{{ production.heading }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ltn__category-area ltn__product-gutter section-bg-1--- pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-area ltn__section-title-2--- text-center">
              <h1 class="section-title">Uyanık Yatağanlı</h1>
            </div>
          </div>
        </div>
        <div class="row ltn__category-slider-active--- slick-arrow-1 justify-content-center rowEQ">
          <div class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">
            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <a href="javascript:void(0);">
                            <span class="category-icon">
                                <svg id="katman_1" x="0px" y="0px"
                                     viewBox="0 0 62.2 60.2" style="enable-background:new 0 0 62.2 60.2;" xml:space="preserve">
<g>
	<path class="st0" d="M48.3,13.9c-4.7,0-8.6,3.9-8.6,8.6s3.9,8.6,8.6,8.6s8.6-3.9,8.6-8.6S53.1,13.9,48.3,13.9z M48.3,29.1
		c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7c3.7,0,6.7,3,6.7,6.7S52,29.1,48.3,29.1z"/>
	<rect x="47.4" y="21.5" class="st0" width="1.9" height="1.9"/>
	<rect x="51.2" y="21.5" class="st0" width="1.9" height="1.9"/>
	<rect x="43.5" y="21.5" class="st0" width="1.9" height="1.9"/>
	<path class="st0" d="M60.7,22.5c0-5.2-3.2-9.6-7.6-11.5V3.3c0-1.6-1.3-2.9-2.9-2.9H4.3c-1.6,0-2.9,1.3-2.9,2.9v42.1
		c0,1.6,1.3,2.9,2.9,2.9h37.3v1.2l4.5,8.9c0.4,0.9,1.3,1.4,2.2,1.4c1,0,1.8-0.5,2.2-1.4l4.5-8.9V32.9C58.4,30.7,60.7,26.8,60.7,22.5
		z M4.3,2.4h45.9c0.5,0,1,0.4,1,1v7c-0.9-0.2-1.9-0.3-2.9-0.3c-2.4,0-4.7,0.7-6.6,1.9H3.4V3.3C3.4,2.8,3.8,2.4,4.3,2.4z M4.3,46.4
		c-0.5,0-1-0.4-1-1V13.9h36c-2.1,2.2-3.5,5.3-3.5,8.6c0,4.4,2.3,8.2,5.7,10.5v13.4H4.3z M48.8,57.5c-0.2,0.4-0.9,0.4-1.1,0l-3.7-7.3
		h8.4L48.8,57.5z M53.1,48.3h-3.8v-9.6h-1.9v9.6h-3.8V33.9c1.2,0.5,2.5,0.8,3.8,0.9v2h1.9v-2c1.3-0.1,2.6-0.4,3.8-0.9V48.3z
		 M48.3,33c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5s10.5,4.7,10.5,10.5S54.1,33,48.3,33z"/>
	<path class="st0" d="M11,4.3H5.3V10H11V4.3z M9.1,8.1H7.2V6.2h1.9V8.1z"/>
	<path class="st0" d="M18.7,4.3h-5.7V10h5.7V4.3z M16.8,8.1h-1.9V6.2h1.9V8.1z"/>
	<path class="st0" d="M26.3,4.3h-5.7V10h5.7V4.3z M24.4,8.1h-1.9V6.2h1.9V8.1z"/>
	<path class="st0" d="M34,15.8h-5.7v9.6h-1.9v-3.8h-5.7v3.8h-1.9v-7.6h-5.7v7.6H11v-5.7H5.3v7.6H34V15.8z M9.1,25.3H7.2v-3.8h1.9
		V25.3z M22.5,23.4h1.9v1.9h-1.9V23.4z M14.9,19.6h1.9v5.7h-1.9V19.6z M32.1,25.3h-1.9v-7.6h1.9V25.3z"/>
	<path class="st0" d="M12.9,29.1c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S17.2,29.1,12.9,29.1z M12.9,31.1
		c1.2,0,2.4,0.4,3.3,1.1l-3.7,3.7H7.3C7.8,33.1,10.1,31.1,12.9,31.1z M7.3,37.7h5.3l3.7,3.7c-0.9,0.7-2.1,1.1-3.3,1.1
		C10.1,42.5,7.8,40.5,7.3,37.7z M17.6,40.1l-3.3-3.3l3.3-3.3c0.7,0.9,1.1,2.1,1.1,3.3S18.3,39.2,17.6,40.1z"/>
	<rect x="22.5" y="34.9" class="st0" width="1.9" height="1.9"/>
	<rect x="26.3" y="34.9" class="st0" width="13.4" height="1.9"/>
	<rect x="22.5" y="38.7" class="st0" width="1.9" height="1.9"/>
	<rect x="26.3" y="38.7" class="st0" width="13.4" height="1.9"/>
	<rect x="22.5" y="42.5" class="st0" width="1.9" height="1.9"/>
	<rect x="26.3" y="42.5" class="st0" width="13.4" height="1.9"/>
</g>
</svg>
                            </span>
                <span class="category-number">01</span>
                <span class="category-title">{{ langcontent.arge }}</span>
                <span class="category-brief">
                               {{ langcontent.argeyazi }}
                            </span>
                <span class="category-btn d-none"><i class="flaticon-right-arrow"></i></span>
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">
            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <a href="javascript:void(0);">
                            <span class="category-icon">

<svg id="katman_1" x="0px" y="0px"
     viewBox="0 0 62.2 60.2" style="enable-background:new 0 0 62.2 60.2;" xml:space="preserve">
<g>
	<path class="st0" d="M11.6,45.9c-6.6,0.2-6.6,9.8,0,10C18.2,55.7,18.2,46.1,11.6,45.9z M11.6,53.9c-1.7,0-3-1.3-3-3s1.3-3,3-3
		c1.7,0,3,1.3,3,3S13.3,53.9,11.6,53.9C11.6,53.9,11.6,53.9,11.6,53.9z"/>
	<path class="st0" d="M24.6,45.9c-6.6,0.2-6.6,9.8,0,10C31.2,55.7,31.2,46.1,24.6,45.9z M24.6,53.9c-1.7,0-3-1.3-3-3s1.3-3,3-3
		c1.7,0,3,1.3,3,3S26.3,53.9,24.6,53.9C24.6,53.9,24.6,53.9,24.6,53.9z"/>
	<path class="st0" d="M37.6,45.9c-6.6,0.2-6.6,9.8,0,10C44.2,55.7,44.2,46.1,37.6,45.9z M37.6,53.9c-1.7,0-3-1.3-3-3
		c0-1.7,1.3-3,3-3c1.7,0,3,1.4,3,3C40.5,52.6,39.2,53.9,37.6,53.9z"/>
	<path class="st0" d="M45.6,50.9c0.2,6.6,9.8,6.6,10,0C55.4,44.3,45.8,44.3,45.6,50.9z M50.6,47.9c1.7,0,3,1.4,3,3
		c0,1.6-1.3,2.9-3,3c-1.7,0-3-1.3-3-3C47.6,49.2,48.9,47.9,50.6,47.9z"/>
	<path class="st0" d="M60.1,43.9c0.6,0,1-0.5,1-1c0-0.5-0.4-1-1-1h-1v-9c0-0.6-0.4-1-1-1h-10c-0.6,0-1,0.4-1,1v9h-5v-9
		c0-0.6-0.4-1-1-1h-10c-0.6,0-1,0.4-1,1v9h-5v-3c0-0.6-0.4-1-1-1h-10c-0.6,0-1,0.4-1,1v3h-11c-0.6,0-1,0.5-1,1c0,0.5,0.4,1,1,1h3.4
		c-6.5,5.2-2.8,16,5.6,16h41c8.5,0,12.2-10.8,5.6-16H60.1z M32.1,33.9h8v8h-8V33.9z M15.1,39.9h8v2h-8V39.9z M59.1,50.9
		c0,3.9-3.1,7-7,7h-41c-3.9,0-7-3.1-7-7s3.1-7,7-7h41C56,43.9,59.1,47,59.1,50.9z M49.1,41.9v-8h8v8H49.1z"/>
	<path class="st0" d="M12.1,29.9c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-2c0-1.1-0.9-2-2-2h-3v-8.9
		c4.5-2.3,6.2-7.7,4-12.2l0-7.9c0-0.6-0.4-1-1-1h-14c-0.6,0-1,0.4-1,1v7.9c-2.2,4.5-0.5,9.9,4,12.2v8.9H12.1z M26.1,33.9h-14v-2h14
		L26.1,33.9z M25.1,1.9v4.3c-3.4-3-8.6-3-12,0V1.9H25.1z M12.1,12.9c0-3.9,3.1-7,7-7c9.3,0.3,9.3,13.7,0,14
		C15.2,19.9,12.1,16.8,12.1,12.9z M17.1,21.7c1.3,0.3,2.7,0.3,4,0v8.2h-4V21.7z"/>
	<path class="st0" d="M24.1,12.9c-0.2-6.6-9.8-6.6-10,0C14.3,19.5,23.9,19.5,24.1,12.9z M16.1,12.9c0-1.7,1.4-3,3-3
		c1.6,0,2.9,1.3,3,3c0,1.7-1.4,3-3,3C17.4,15.8,16.1,14.5,16.1,12.9z"/>
</g>
</svg>

                            </span>
                <span class="category-number">02</span>
                <span class="category-title">{{ langcontent.uretim }}</span>
                <span class="category-brief">
                                {{ langcontent.uretimyazi }}
                            </span>
                <span class="category-btn d-none"><i class="flaticon-right-arrow"></i></span>
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">
            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <a href="javascript:void(0);">
                            <span class="category-icon">
                                <svg id="katman_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                     viewBox="0 0 61.5 58.6" style="enable-background:new 0 0 61.5 58.6;" xml:space="preserve">
<g>
	<path class="st0" d="M40.9,19.6l-6.4-0.9l-2.9-5.8c-0.2-0.5-0.8-0.7-1.3-0.5c-0.2,0.1-0.4,0.3-0.5,0.5L27,18.7l-6.4,0.9
		c-0.5,0.1-0.9,0.6-0.8,1.2c0,0.2,0.1,0.4,0.3,0.5l4.6,4.5l-1.1,6.5c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.2,0.7,0.2,1,0.1l5.8-3.1l5.8,3
		c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.6-0.1c0.3-0.2,0.5-0.6,0.4-1l-1.1-6.4l4.6-4.6c0.4-0.4,0.4-1,0-1.4
		C41.4,19.7,41.2,19.6,40.9,19.6L40.9,19.6z M35.1,24.8c-0.2,0.2-0.3,0.6-0.3,0.9l0.8,4.9l-4.4-2.3c-0.3-0.2-0.6-0.2-0.9,0l-4.4,2.3
		l0.9-4.9c0.1-0.3-0.1-0.7-0.3-0.9l-3.6-3.5l4.9-0.7c0.3,0,0.6-0.2,0.8-0.5l2.2-4.5l2.2,4.5c0.1,0.3,0.4,0.5,0.8,0.5l4.9,0.7
		L35.1,24.8z M30.8,5.7c-10,0-18.1,8.1-18.1,18.1S20.7,42,30.8,42s18.1-8.1,18.1-18.1C48.9,13.8,40.8,5.7,30.8,5.7z M30.8,40
		c-8.9,0-16.1-7.2-16.1-16.1c0-8.9,7.2-16.1,16.1-16.1s16.1,7.2,16.1,16.1c0,0,0,0,0,0C46.9,32.7,39.7,39.9,30.8,40z M61.2,44.4
		L51.8,35v-0.1l-0.4-4.5l2.9-3.4c1.6-1.9,1.6-4.7,0-6.5l-3-3.4l0.4-4.5c0.2-2.5-1.5-4.7-3.9-5.2l-4.4-1L41,2.4c-1.3-2.1-3.9-3-6.2-2
		l-4.2,1.8l-4.2-1.7c-2.3-0.9-4.9-0.1-6.2,2l-2.3,3.9l-4.4,1.1c-2.4,0.6-4,2.8-3.8,5.3l0.4,4.5l-3,3.4c-1.6,1.9-1.6,4.7,0,6.5l3,3.4
		l-0.3,4.2l-9.6,9.6c-0.4,0.4-0.4,1,0,1.4C0.5,46,0.7,46.1,1,46.1l0,0L12.6,46l-0.1,11.6c0,0.4,0.2,0.8,0.6,0.9
		c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3l7.3-7.2c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.5,5.5L14.6,45
		c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3L3.4,44.1l6.8-6.8c0.6,1.6,1.9,2.7,3.5,3.1l4.4,1l2.3,3.9c0.9,1.5,2.5,2.3,4.3,2.3
		c0.7,0,1.4-0.1,2-0.4l4.2-1.8l4.2,1.7c2.3,1,4.9,0.1,6.2-2l2.3-3.9l4.4-1c1.5-0.4,2.8-1.4,3.4-2.8l6.7,6.7L47.9,44
		c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7L47,55.1l-5.5-5.5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l7.3,7.3
		c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0.6-0.5,0.6-0.9L48.9,46l11.6,0.1c0.4,0,0.8-0.2,0.9-0.6
		C61.6,45.2,61.5,44.7,61.2,44.4z M47.5,38.3l-4.8,1.2c-0.3,0.1-0.5,0.2-0.6,0.5l-2.5,4.2c-0.8,1.3-2.4,1.8-3.7,1.2l-4.6-1.9
		c-0.2-0.1-0.5-0.1-0.8,0l-4.5,1.9c-1.4,0.6-3,0.1-3.7-1.2L19.6,40c-0.1-0.2-0.4-0.4-0.6-0.5l-4.8-1.1c-1.4-0.3-2.4-1.7-2.3-3.1
		l0.4-4.9c0-0.3-0.1-0.5-0.2-0.7l-3.2-3.8c-1-1.1-1-2.8,0-3.9l3.2-3.8c0.2-0.2,0.3-0.5,0.2-0.7l-0.4-4.9c-0.1-1.5,0.8-2.8,2.3-3.2
		l4.8-1.1c0.3-0.1,0.5-0.2,0.6-0.5L22,3.5c0.8-1.3,2.4-1.8,3.8-1.2l4.5,1.9c0.2,0.1,0.5,0.1,0.8,0l4.5-1.9c1.4-0.6,3-0.1,3.7,1.2
		l2.6,4.2c0.1,0.2,0.4,0.4,0.6,0.5l4.8,1.1c1.4,0.3,2.4,1.7,2.3,3.2l-0.4,4.9c0,0.3,0.1,0.5,0.2,0.7l3.2,3.7c1,1.1,1,2.8,0,3.9
		l-3.2,3.8c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,4.9C49.9,36.6,48.9,37.9,47.5,38.3z"/>
</g>
</svg>

                            </span>
                <span class="category-number">03</span>
                <span class="category-title">{{ langcontent.kalite }}</span>
                <span class="category-brief">
                                {{ langcontent.kaliteyazi }}
                            </span>
                <span class="category-btn d-none"><i class="flaticon-right-arrow"></i></span>
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">
            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <a href="javascript:void(0);">
                            <span class="category-icon">
                                <svg id="katman_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                                     viewBox="0 0 62.2 60.2" style="enable-background:new 0 0 62.2 60.2;" xml:space="preserve">
<path class="st0" d="M59.9,17.2h-17V1.5c0-0.7-0.6-1.3-1.3-1.3H2.3C1.5,0.2,1,0.8,1,1.5V50c0,5.8,4.7,10.5,10.5,10.5h39.3
	c5.8,0,10.5-4.7,10.5-10.5V18.6C61.3,17.8,60.7,17.2,59.9,17.2z M47.4,36.3l0.5,1.7l-1.4,1.1c-0.2,0.2-0.4,0.4-0.5,0.7l-0.5,1.7
	h-1.7c-0.3,0-0.6,0.1-0.8,0.3l-1.4,1l-1.4-1c-0.2-0.2-0.5-0.3-0.8-0.3h-1.7l-0.5-1.7c-0.1-0.3-0.2-0.5-0.5-0.7l-1.4-1.1l0.5-1.7
	c0.1-0.3,0.1-0.5,0-0.8l-0.5-1.7l1.4-1.1c0.2-0.2,0.4-0.4,0.5-0.7l0.5-1.7h1.7c0.3,0,0.6-0.1,0.8-0.3l1.4-1l1.4,1
	c0.2,0.2,0.5,0.3,0.8,0.3h1.7l0.5,1.7c0.1,0.3,0.2,0.5,0.5,0.7l1.4,1.1l-0.5,1.7C47.3,35.8,47.3,36.1,47.4,36.3z M42.4,49
	c-0.5-0.4-1.2-0.4-1.7,0l-1.7,0.9v-5.7l1.7,1.3c0.5,0.3,1.1,0.3,1.6,0l1.7-1.3v5.7L42.4,49z M11.4,57.9c-4.3,0-7.9-3.5-7.9-7.9V2.8
	h36.7v23.9l-1.3,1h-2.3c-0.6,0-1.1,0.4-1.3,0.9l-0.7,2.2l-1.8,1.4c-0.4,0.3-0.6,0.9-0.5,1.4l0.7,2.2l-0.7,2.2
	c-0.2,0.5,0,1.1,0.5,1.4l1.8,1.4l0.7,2.2c0.1,0.4,0.5,0.8,1,0.9V52c0,0.7,0.6,1.3,1.3,1.3c0.2,0,0.4,0,0.6-0.1l2.1-1.1
	c0.5,2.2,1.6,4.3,3.4,5.8H11.4z M58.6,50c0,4.3-3.5,7.9-7.9,7.9c-3.4,0-6.5-2.2-7.5-5.5l1.5,0.8c0.6,0.3,1.4,0.1,1.8-0.6
	c0.1-0.2,0.1-0.4,0.1-0.6v-7.9c0.5-0.1,0.8-0.4,1-0.9l0.7-2.2l1.8-1.4c0.4-0.3,0.6-0.9,0.5-1.4L50,35.9l0.7-2.2
	c0.2-0.5,0-1.1-0.5-1.4l-1.8-1.4l-0.7-2.2c-0.2-0.5-0.7-0.9-1.3-0.9h-2.3l-1.3-1v-6.9h15.7V50z"/>
</svg>

                            </span>
                <span class="category-number">04</span>
                <span class="category-title">{{ langcontent.garanti }}</span>
                <span class="category-brief">
                                {{ langcontent.garantiyazi }}
                            </span>
                <span class="category-btn d-none"><i class="flaticon-right-arrow"></i></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.st0{fill:#FF0000;}
.bg-image-right-before{
  --img:url('https://source.unsplash.com/850x700');
}
.bg-image-right-before::before{
  background-image: var(--img);
}
</style>
<script>
import FsLightbox from "fslightbox-vue/v3";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import iso from "../../axios";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    FsLightbox
  },
  props:['langcontent'],
  data() {
    return {
      toggler: false,
      show: false,
      about:[],
      sliderItems:[],
      production:[],
      categories:[],
      video:[],
    }
  },
  created() {
    iso.get('slider')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.sliderItems.push(data[key])
          }
          this.show = true

        })
        .catch(error => {
          console.log(error);
        })
    iso.get('about')
        .then(response => {
          this.about = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('production')
        .then(response => {
          this.production = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('video')
        .then(response => {
          this.video = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('categories')
        .then(response => {
          this.categories = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
}


</script>
