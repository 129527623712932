<template>
  <div>
    <a href="https://api.whatsapp.com/send?phone=905305000616" class="float" target="_blank">
      <i class="fab fa-whatsapp my-float"></i>
    </a>
    <footer class="ltn__footer-area  ">
      <div class="footer-top-area  section-bg-2 plr--5">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-md-6 col-sm-6 col-12">
              <div class="footer-widget footer-about-widget">
                <div class="footer-logo">
                  <div class="site-logo">
                    <img src="../../../public/assets/logoW.png" alt="Logo">
                  </div>
                </div>
                <p class="mt-3">{{ langcontent.logoyazi}}</p>
                <div class="ltn__social-media mt-20">
                  <!--ul>
                      <li><a href="#" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                      <li><a href="#" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="#" title="Linkedin"><i class="fab fa-linkedin"></i></a></li>
                      <li><a href="#" title="Youtube"><i class="fab fa-youtube"></i></a></li>
                  </ul-->
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-6 col-6">
              <div class="footer-widget footer-menu-widget clearfix">
                <h4 class="footer-title">{{ langcontent.menu }}</h4>
                <div class="footer-menu">
                  <ul>
                    <li><a href="/anasayfa" title="Anasayfa" rel="nofollow">{{ langcontent.anasayfa }}</a></li>
                    <li><router-link to="/kurumsal" title="Kurumsal" rel="nofollow">{{ langcontent.kurumsal }}</router-link></li>
                    <li><router-link to="/uretim" title="Üretim" rel="nofollow">{{ langcontent.uretim }}</router-link></li>
                    <li><router-link to="iletisim" title="İletişim" rel="nofollow">{{ langcontent.iletisim}}</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-6 col-6">
              <div class="footer-widget footer-menu-widget clearfix">
                <h4 class="footer-title">{{ langcontent.urunler}}</h4>
                <div class="footer-menu">
                  <ul>
                    <li v-for="item in categories.slice(0 ,4)">
                      <router-link :to="'/kategori/'+item.seo" :title="item.heading" rel="nofollow">{{ item.heading }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 col-12">
              <div class="footer-widget footer-newsletter-widget">
                <h4 class="footer-title">{{ langcontent.iletisim }}</h4>
                <div class="footer-address">
                  <ul>
                    <li>
                      <div class="footer-address-icon">
                        <i class="icon-placeholder"></i>
                      </div>
                      <div class="footer-address-info">
                        <p>{{ contact.addr }}</p>
                      </div>
                    </li>
                    <li>
                      <div class="footer-address-icon">
                        <i class="icon-call"></i>
                      </div>
                      <div class="footer-address-info">
                        <p><a :href="'tel:+9'+contact.tel">{{ contact.tel }}</a></p>
                      </div>
                    </li>
                    <li>
                      <div class="footer-address-icon">
                        <i class="icon-mail"></i>
                      </div>
                      <div class="footer-address-info">
                      </div>
                      <p><a :href="'mailto:'+contact.mail">{{ contact.mail }}</a></p>
                    </li>

                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
        <div class="container-fluid ltn__border-top-2">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="ltn__copyright-design clearfix">
                <p>{{ langcontent.copyright }} @ Uyanık Yatağanlı <span class="current-year"></span></p>
              </div>
            </div>
            <div class="col-md-6 col-12 align-self-center">
              <div class="ltn__copyright-menu text-end">
                <ul>
                  <li>Powered by <a href="http://gifadwork.com/" title="Gif Adwork">Gif Adwork</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="preloader d-none" id="preloader">
      <div class="preloader-inner">
        <div class="spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import iso from "../../axios";

export default {
  props:['langcontent'],
  data() {
    return {
      categories: [],
      contact: []
    }
  },
  created() {

    iso.get('categories')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.categories.push(data[key])
          }
        })
    iso.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  watch:{
    $route (to, from){
      //console.clear();
      setTimeout(function(){
       // console.clear();
        console.log(
            '                                   ######\n' +
            '                                ############\n' +
            '                               ############\n' +
            '                               #######\n' +
            '   ##############   ####### #############\n' +
            ' #################  ####### ##############\n' +
            '##################          ############\n' +
            '#######    #######  #######    #######\n' +
            '#######    #######  #######    #######\n' +
            '##################  #######    #######\n' +
            ' #################  #######    #######\n' +
            '   ###############  #######    #######\n' +
            '          ########  #######    #######\n' +
            ' ################\n' +
            ' ##############\n' +
            '    ########')}, 1000);
    }
  }
}



</script>